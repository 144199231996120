import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation from react-router-dom
import Navbar from "./landing-page/navbar";
import LFooter from "./landing-page/footer";
import "../pages/landing-screen.css";
import SaleBanner from "./SaleBanner/SaleBanner";

export const Layout = ({ children }) => {
  const [darkNavbar, setDarkNavbar] = useState(false);
  const landingScreenRef = useRef(null);
  const location = useLocation(); // Get the current location

  useEffect(() => {
    // List of routes that require a dark navbar
    const darkNavbarRoutes = [
      "/policy",
      "/terms-and-conditions",
      "/benefits",
      "/interactive-demo",
      "/teachers",
    ];

    // Check if the current route is in the list of dark navbar routes
    if (darkNavbarRoutes.includes(location.pathname)) {
      setDarkNavbar(true);
    } else {
      setDarkNavbar(false);
    }

    // Add scroll event listener only if not on darkNavbarRoutes
    if (
      landingScreenRef.current &&
      !darkNavbarRoutes.includes(location.pathname)
    ) {
      const handleScroll = (e) => {
        if (e.target.scrollTop > 300) {
          setDarkNavbar(true);
        } else {
          setDarkNavbar(false);
        }
      };

      landingScreenRef.current.addEventListener("scroll", handleScroll);

      return () => {
        landingScreenRef.current.removeEventListener("scroll", handleScroll);
      };
    }
  }, [location.pathname]); // Add location.pathname as a dependency

  return (
    <main>
      <Navbar darkNavbar={darkNavbar} />
      <main className="landing-screen-container" ref={landingScreenRef}>
        <div>{children}</div>
      </main>
    </main>
  );
};

export default Layout;
