import * as React from "react";
import { Link } from "react-router-dom";

import aboutImg from "../../assets/images/about/about-company.jpg";

import "./AboutUs.css";

const AboutUs = () => {
  return (
    <>
      <div id="about" className="agency-about-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div
                className="agency-about-image pr-20"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <img src={aboutImg} alt="about" className="about-image" />
              </div>
            </div>

            <div className="col-lg-6">
              <div
                className="agency-about-content pl-20"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <div className="agency-about-title">
                  <h2>About Our Company</h2>

                  <div className="agency-text">
                    <p>
                      InspirEDU is the ultimate customizable platform designed
                      to simplify training and education. Whether you're a
                      teacher, trainer, or small business owner, InspirEDU helps
                      you create, manage, and deliver courses efficiently,
                      saving you time and boosting productivity. With multimedia
                      support, automated notifications, and easy student
                      management, you can focus on what matters most—teaching
                      and growing. Start creating your courses today with
                      InspirEDU, where simplicity meets inspiration.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
