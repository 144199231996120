export const generalPackages = {
  heading: "General Packages",
  basicPackage: {
    heading: "Basic Package",
    price: 99,
    persons: "Up to 15 Users",
    modules: "50 Tests/Training Modules",
    suitableForBusiness:
      "For small businesses, small teams or small startups to help with training",
    suitableForEducation:
      "For small classes, tutoring groups, or after-school programs",
  },
  advancedPackage: {
    heading: "Advanced Package",
    price: 149,
    persons: "Up to 30 Users",
    modules: "100 Tests/Training Modules",
    suitableForBusiness:
      "Different departmental training needs in medium-sized businesses",
    suitableForEducation:
      "Elementary classroom settings with regular testing needs",
  },
  premiumPackage: {
    heading: "Premium Package",
    price: 249,
    persons: "Up to 150 Users",
    modules: "300 Tests/Training Modules",
    suitableForBusiness:
      "Larger departments or entire companies requiring extensive employee training",
    suitableForEducation:
      "High school teachers managing multiple classes throughout the day",
  },
  enterprisePackage: {
    heading: "Enterprise Package",
    price: 399,
    persons: "Up to 300 Users",
    modules: "Unlimited Tests/Training Modules",
    suitableForBusiness:
      "Large corporations with varied training needs across multiple departments",
    suitableForEducation:
      "Entire schools needing extensive test & training management",
  },
};
