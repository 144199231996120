import React from "react";
import "./SupademoPage.css";
import LFooter from "../../components/landing-page/footer";

const SupademoPage = () => {
  return (
    <>
      <div className="landing-screen-container">
        <div className="supademo-container">
          <h1 className="text-primary">Explore Our Interactive Demo</h1>
          <p>
            Discover how inspir-edu transforms the learning experience with our
            interactive demo. This showcase provides an in-depth look at the
            platform’s unique features designed to facilitate effective
            education, empower learners, and streamline educational processes.
            Experience firsthand how inspir-edu can support your educational
            journey and goals.
          </p>
          <div className="supa-demo-container">
            <iframe
              src="https://app.supademo.com/embed/cm1pauznf14lxe4ytdfdy01q4?embed_v=2"
              loading="lazy"
              title="Breakthroughtrainingsystems Demo"
              allow="clipboard-write"
              frameBorder="0"
              webkitAllowFullScreen
              mozAllowFullScreen
              allowFullScreen
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </div>
        </div>
        <LFooter />
      </div>
    </>
  );
};

export default SupademoPage;
