import React from "react";
import "./TeachersPage.css";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { motion } from "framer-motion";
import LFooter from "../../components/landing-page/footer";
import headerImage from "../../assets/teachers-page-header.jpg"; // Adjust the path to your image
import { PaperPlaneTilt } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import Pricing from "../../components/Pricing/Pricing";
import DemoSection from "../../components/landing-page/demo-section/DemoSection";
import GetDemo from "../../components/GetDemo/GetDemo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faChalkboardTeacher,
  faCircleCheck,
  faClock,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import cardImage1 from "../../assets/about-company.jpg"; // Import images for the cards
import cardImage2 from "../../assets/save-time.jpg";
import cardImage3 from "../../assets/integrate-multimedia.jpg";
import cardImage4 from "../../assets/deadline-modified.jpg";
import cardImage5 from "../../assets/accountability.jpg";

const TeachersPage = () => {
  const ADMIN_BASE_URL = "https://admin.inspir-edu.com/register?step=1";

  const handleSubscribeClick = () => {
    window.open(ADMIN_BASE_URL, "_blank");
  };

  return (
    <div className="teachers-page-container">
      {/* Top Header Section */}
      <div
        className="top-header-section"
        style={{
          backgroundImage: `url(${headerImage})`,
        }}
      >
        <div className="header-content">
          <h1>Empower Your Teaching with InspirEDU</h1>
          <p>Efficiently manage your courses and students, all in one place.</p>
          <div className="hero-section-action-buttons" style={{ padding: 0 }}>
            <motion.button
              type="button"
              className="btn btn-lg rounded-pill btn-success px-5 d-flex align-items-center gap-2 large-button"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              transition={{ duration: 0.2 }}
              onClick={handleSubscribeClick}
            >
              <span>Join for Free</span>
              <PaperPlaneTilt size={20} />
            </motion.button>
          </div>
        </div>
      </div>

      {/* <Container className="teachers-page py-5">
        <h1 className="text-center mb-4">Why Teachers Should Use InspirEDU</h1>
        <p className="text-center mb-5">
          Simplify Course Management for Educators. We’ve designed InspirEDU
          with teachers in mind, offering flexible and efficient tools to manage
          your courses and students all in one place.
        </p>

        <Row className="g-4">
          <Col md={6} lg={4}>
            <Card className="h-100">
              <Card.Body>
                <Card.Title>1. Tailored Plans for Teachers</Card.Title>
                <Card.Text>
                  Our first two plans are specifically catered to the needs of
                  elementary teachers and multi-class professors. Whether you’re
                  managing 3 courses per week or more, our platform is built to
                  handle the workload of a typical 9-month school year, ensuring
                  you can easily manage the right number of students without
                  hassle.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6} lg={4}>
            <Card className="h-100">
              <Card.Body>
                <Card.Title>2. Save Time, Focus on Teaching</Card.Title>
                <Card.Text>
                  By reusing your course materials from semester to semester and
                  only making updates as needed, you’ll save significant time.
                  No need to recreate lessons or manually invite students—simply
                  update your materials and invite your entire class with just a
                  few clicks.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6} lg={4}>
            <Card className="h-100">
              <Card.Body>
                <Card.Title>3. Easily Integrate Multimedia</Card.Title>
                <Card.Text>
                  Inspire and engage your students by easily integrating videos,
                  pictures, PDFs, and more into your courses. Offering multiple
                  formats allows for diverse and interactive learning
                  experiences.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6} lg={4}>
            <Card className="h-100">
              <Card.Body>
                <Card.Title>4. Set Deadlines and Stay Notified</Card.Title>
                <Card.Text>
                  With our weekly deadline features, you can ensure students
                  stay on track, and you’ll be notified as soon as they complete
                  their assignments. This creates accountability and ensures
                  timely completion, giving you peace of mind.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6} lg={4}>
            <Card className="h-100">
              <Card.Body>
                <Card.Title>5. Accuracy and Accountability</Card.Title>
                <Card.Text>
                  InspirEDU tracks and organizes all student submissions in one
                  place, ensuring a streamlined and accountable process for you
                  and your students. The platform makes it easy to review,
                  grade, and track student progress accurately.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container> */}
      <div
        style={{
          padding: "2rem",
          background: "linear-gradient(135deg, #f8f9fa, #e2e2e2)", // Gradient background for the container
        }}
      >
        <Container className="teachers-page py-5 px-4">
          <h1 className="text-center mb-4">
            Why Teachers Should Use InspirEDU
          </h1>
          <p className="text-center mb-5">
            Simplify Course Management for Educators. We’ve designed InspirEDU
            with teachers in mind, offering flexible and efficient tools to
            manage your courses and students all in one place.
          </p>

          <Row className="g-4">
            {/* Card 1 */}
            <Col md={6} lg={4}>
              <Card
                className="h-100 shadow-sm border-0"
                style={{
                  borderRadius: "10px",
                  transition: "transform 0.3s",
                  overflow: "hidden", // Ensure the image stays within the card's borders
                }}
              >
                <Card.Img
                  variant="top"
                  src={cardImage1}
                  alt="Tailored Plans"
                  style={{ height: "200px", objectFit: "cover" }}
                />
                <Card.Body>
                  <Card.Title className="d-flex align-items-center">
                    <FontAwesomeIcon
                      className="me-2 text-primary"
                      size="lg"
                      icon={faChalkboardTeacher}
                    />
                    1. Tailored Plans for Teachers
                  </Card.Title>
                  <Card.Text className="text-muted">
                    Our first two plans are specifically catered to the needs of
                    elementary teachers and multi-class professors. Manage up to
                    3 courses per week and handle the workload of a typical
                    9-month school year effortlessly.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            {/* Card 2 */}
            <Col md={6} lg={4}>
              <Card
                className="h-100 shadow-sm border-0"
                style={{
                  borderRadius: "10px",
                  transition: "transform 0.3s",
                  overflow: "hidden",
                }}
              >
                <Card.Img
                  variant="top"
                  src={cardImage2}
                  alt="Save Time"
                  style={{ height: "200px", objectFit: "cover" }}
                />
                <Card.Body>
                  <Card.Title className="d-flex align-items-center">
                    <FontAwesomeIcon
                      className="me-2 text-success"
                      size="lg"
                      icon={faClock}
                    />
                    2. Save Time, Focus on Teaching
                  </Card.Title>
                  <Card.Text className="text-muted">
                    Reuse course materials from semester to semester, making
                    only necessary updates. Invite your entire class with just a
                    few clicks, saving you significant time.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            {/* Card 3 */}
            <Col md={6} lg={4}>
              <Card
                className="h-100 shadow-sm border-0"
                style={{
                  borderRadius: "10px",
                  transition: "transform 0.3s",
                  overflow: "hidden",
                }}
              >
                <Card.Img
                  variant="top"
                  src={cardImage3}
                  alt="Integrate Multimedia"
                  style={{ height: "200px", objectFit: "cover" }}
                />
                <Card.Body>
                  <Card.Title className="d-flex align-items-center">
                    <FontAwesomeIcon
                      className="me-2 text-info"
                      size="lg"
                      icon={faPlayCircle}
                    />
                    3. Easily Integrate Multimedia
                  </Card.Title>
                  <Card.Text className="text-muted">
                    Inspire and engage students by integrating videos, images,
                    PDFs, and more. Create diverse and interactive learning
                    experiences with multiple formats.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            {/* Card 4 */}
            <Col md={6} lg={4}>
              <Card
                className="h-100 shadow-sm border-0"
                style={{
                  borderRadius: "10px",
                  transition: "transform 0.3s",
                  overflow: "hidden",
                }}
              >
                <Card.Img
                  variant="top"
                  src={cardImage4}
                  alt="Set Deadlines"
                  style={{ height: "200px", objectFit: "cover" }}
                />
                <Card.Body>
                  <Card.Title className="d-flex align-items-center">
                    <FontAwesomeIcon
                      className="me-2 text-primary"
                      size="lg"
                      icon={faBell}
                    />
                    4. Set Deadlines and Stay Notified
                  </Card.Title>
                  <Card.Text className="text-muted">
                    Utilize weekly deadline features to keep students on track.
                    Receive notifications when assignments are completed,
                    ensuring accountability and timely completion.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            {/* Card 5 */}
            <Col md={6} lg={4}>
              <Card
                className="h-100 shadow-sm border-0"
                style={{
                  borderRadius: "10px",
                  transition: "transform 0.3s",
                  overflow: "hidden",
                }}
              >
                <Card.Img
                  variant="top"
                  src={cardImage5}
                  alt="Accuracy and Accountability"
                  style={{ height: "200px", objectFit: "cover" }}
                />
                <Card.Body>
                  <Card.Title className="d-flex align-items-center">
                    <FontAwesomeIcon
                      className="me-2 text-success"
                      size="lg"
                      icon={faCircleCheck}
                    />
                    5. Accuracy and Accountability
                  </Card.Title>
                  <Card.Text className="text-muted">
                    Track and organize student submissions in one place. Easily
                    review, grade, and monitor progress accurately for a
                    streamlined process.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Call-to-Action Button */}
          <div
            className="hero-section-action-buttons"
            style={{ padding: 0, marginTop: "3rem" }}
          >
            <motion.button
              type="button"
              className="btn btn-lg rounded-pill btn-success px-5 d-flex align-items-center gap-2 large-button"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              transition={{ duration: 0.2 }}
              onClick={handleSubscribeClick}
            >
              <span>Join for Free</span>
              <PaperPlaneTilt size={20} />
            </motion.button>
          </div>
        </Container>
      </div>
      <GetDemo />
      <Pricing />
      <Container className="teachers-page py-5">
        <DemoSection onClick={handleSubscribeClick} />
      </Container>
      <LFooter />
    </div>
  );
};

export default TeachersPage;
