import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import demoImage from "../../assets/demo-image.png";
import "./GetDemo.css"; // Add styles to make it more appealing

const GetDemo = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    request: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false); // To show confirmation message

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const serviceID = "service_kq8hf5c";
    const templateID = "template_x43br4r";
    const userID = "FuqNV8mUCsn7GdGTO";

    emailjs
      .send(serviceID, templateID, formData, userID)
      .then((response) => {
        alert("Demo request submitted successfully!");
        setIsSubmitted(true); // Set submission status
        setFormData({
          name: "",
          email: "",
          company: "",
          request: "",
        });
      })
      .catch((err) => {
        console.error("Failed to send email. Error: ", err);
      });
  };

  return (
    <section
      className="demo-section py-5"
      style={{ backgroundColor: "#f0f4ff" }}
      id="demo-form"
    >
      <div className="container">
        <div className="row align-items-center">
          <h2 className="display-5 text-center mb-4 font-weight-bold">
            Experience inspirEDU with a Free Demo
          </h2>
          <p className="lead text-center">
            Discover how our platform can enhance learning, improve engagement,
            and streamline processes for your business.
          </p>
          {/* Left Section with Image */}
          <div className="col-md-6">
            <ul className="list-unstyled">
              <li>&#x27A4; Personalized walkthrough of the platform</li>
              <li>&#x27A4; Explore features to boost employee engagement</li>
              <li>
                &#x27A4; Transparent pricing options tailored for your business
              </li>
            </ul>
            <img
              src={demoImage} // Replace with actual image
              alt="Product demo illustration"
              className="img-fluid mt-4"
            />
          </div>

          {/* Right Section with Form */}
          <div className="col-md-6">
            <div className="p-4 bg-white shadow-sm rounded">
              <h4 className="mb-3">Request Your Free Demo</h4>
              {isSubmitted ? (
                <p className="text-success">
                  Thank you! We will get in touch soon.
                </p>
              ) : (
                <form onSubmit={sendEmail}>
                  <div className="form-group">
                    <label htmlFor="name">Your Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="John Doe"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Work Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="you@company.com"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="company">Company Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="company"
                      placeholder="Your Company"
                      value={formData.company}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="request">
                      Tell us what you’re looking for
                    </label>
                    <textarea
                      className="form-control"
                      id="request"
                      rows="3"
                      placeholder="How can inspirEDU help your business?"
                      value={formData.request}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary btn-block mt-3"
                  >
                    Request a Demo
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetDemo;
