import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";
import WhyUsSvg from "../../../assets/Studying-cuate.svg";
import { PaperPlaneTilt } from "@phosphor-icons/react";
import { Accordion } from "react-bootstrap";
import WhyChooseUsImage from "../../../assets/why-choose-us.jpg";

import "./segmentations.css"; // Custom styles for the section
import { Link } from "react-router-dom";

const Segmentations = ({ onClick }) => {
  return (
    <section className="why-choose-us-section mb-5">
      <Container fluid>
        <Row className="align-items-center mb-5">
          <h2 className="benefit-title poppins-medium text-center">
            Why Choose Us?
          </h2>
        </Row>
        <Row className="align-items-center">
          <Col md={6} className="text-section">
            <p className="teachers-benefits-text mb-4">
              Teachers and Educators, <Link to="/teachers">Click here!</Link>
            </p>

            {/* Accordion Section */}
            <Accordion flush defaultActiveKey="0" className="benefit-accordion">
              {/* Small Business Owners */}
              <Accordion.Item eventKey="0">
                <Accordion.Header>Small Business Owners</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Keep your team on track with easy task management and
                    training progress tracking.
                  </p>
                  <p>
                    Centralize your training programs, enjoy cost-effective
                    analytics, and simplify compliance with industry standards
                    and regulations.
                  </p>
                </Accordion.Body>
              </Accordion.Item>

              {/* Training Coordinators */}
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Trainers and Content Creators
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Our platform is tailored to meet the dynamic needs of
                    trainers and content creators. Whether you’re managing
                    multiple training programs or creating educational content,
                    we provide the flexibility and tools to streamline your
                    process.
                  </p>
                  <p>
                    Easily create courses using videos, images, PDFs, quizzes,
                    and other multimedia formats. Adapt training materials in
                    seconds and design programs effortlessly with our intuitive
                    drag-and-drop interface.
                  </p>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>Teachers and Educators</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Inspire and engage your students by easily integrating
                    videos, pictures, PDFs, and more into your courses. Offering
                    multiple formats allows for diverse and interactive learning
                    experiences.
                  </p>
                  <p>
                    With our weekly deadline features, you can ensure students
                    stay on track, and you’ll be notified as soon as they
                    complete their assignments. This creates accountability and
                    ensures timely completion, giving you peace of mind.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* Call to Action */}
            {/* <div className="revolutionize-hero-actions text-center mt-4">
              <motion.button
                type="button"
                className="btn btn-lg rounded-pill btn-theme2-secondary px-5 d-flex align-items-center gap-2 large-button"
                whileHover={{ scale: 1.05 }} // Add hover animation
                whileTap={{ scale: 0.95 }} // Add tap animation
                transition={{ duration: 0.2 }}
                onClick={onClick}
              >
                <span>Sign Up</span>
                <PaperPlaneTilt size={20} />
              </motion.button>
            </div> */}
          </Col>

          {/* Left Image Section */}
          <Col md={6} className="image-section">
            <img
              src={WhyChooseUsImage}
              alt="Illustration"
              className="illustration-img"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Segmentations;
