import React from "react";
import "./ProductHunt.css";

const ProductHunt = () => {
  return (
    <div className="container product-hunt-section">
      <div className="iframe-container">
        <iframe
          title="Product Hunt Iframe"
          style={{ border: "none" }}
          src="https://cards.producthunt.com/cards/posts/488944?v=1"
          width="500"
          height="405"
          frameBorder="0"
          scrolling="no"
          allowFullScreen
        ></iframe>
      </div>
      <div className="iframe-content">
        <h3>Join Our Product Hunt Launch!</h3>
        <p>
          We’re excited to showcase our latest innovations on Product Hunt!
          Explore the features and benefits of our platform and join the
          discussion. Don't miss the chance to support and upvote our product.
        </p>
        <ul>
          <li>
            💻 Designed for Non-Tech-Savvy Users: Built with simplicity in mind
            for ease of use by users of all ages and tech abilities.
          </li>
          <li>
            ⏱ Time-Saving Tools: Streamlined course and content creation to save
            time for small business owners and educators.
          </li>
          <li>
            📚 Versatile Content Formats: Easily load tests, exams, seminars,
            and courses using PDFs, videos, and more.
          </li>
          <li>
            👥 Team Collaboration: Invite and manage team members, students, or
            trainees with a few clicks.
          </li>
          <li>
            📈 Productivity-Boosting Features: Tools specifically designed to
            enhance productivity and improve learning experiences.
          </li>
          <li>
            🛠 Comprehensive Platform: Ideal for creating and managing courses,
            quizzes, training sessions, and masterclasses.
          </li>
        </ul>

        <button
          className="product-hunt-button"
          onClick={() =>
            window.open(
              "https://www.producthunt.com/products/breakthrough-training-systems#inspiredu",
              "_blank"
            )
          }
        >
          Visit Us on Product Hunt
        </button>
      </div>
    </div>
  );
};

export default ProductHunt;
