// AppRoutes.js
import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import LandingPage from "./pages/landing-page";
import Policy from "./components/policy/Policy";
import Terms from "./components/Terms/Terms";
import Benefits from "./components/landing-page/benefits/Benefits";
import SupademoPage from "./pages/SupademoPage/SupademoPage";
import TeachersPage from "./pages/Teachers/TeachersPage";

const AppRoutes = [
  {
    index: true,
    element: <LandingPage />,
  },
  {
    path: "/counter",
    element: <Counter />,
  },
  {
    path: "/benefits",
    element: <Benefits />,
  },
  {
    path: "/fetch-data",
    element: <FetchData />,
  },
  {
    path: "/policy",
    element: <Policy />, // New Policy route
  },
  {
    path: "/terms-and-conditions",
    element: <Terms />, // New Terms and Conditions route
  },
  {
    path: "/interactive-demo",
    element: <SupademoPage />,
  },
  {
    path: "/teachers",
    element: <TeachersPage />,
  },
];

export default AppRoutes;
