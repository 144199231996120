import "./Policy.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShieldAlt,
  faFileContract,
  faUndo,
  faCookieBite,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import LFooter from "../landing-page/footer";

const Policy = () => {
  return (
    <div className="landing-screen-container">
      <div className="policy-container">
        <h1>Our Policy</h1>

        {/* Policy Sections */}
        <section id="privacy-policy" className="policy-section">
          <h2>
            <FontAwesomeIcon icon={faShieldAlt} /> Privacy Policy
          </h2>
          <p>
            We value your privacy and are committed to protecting your personal
            data. Our privacy policy outlines how we collect, use, and safeguard
            your information.
          </p>
        </section>

        <section id="terms-of-service" className="policy-section">
          <h2>
            <FontAwesomeIcon icon={faFileContract} /> Terms of Service
          </h2>
          <p>
            By using our services, you agree to our terms of service. These
            terms outline your rights, obligations, and responsibilities when
            using our platform.
          </p>
        </section>

        <section id="refund-policy" className="policy-section">
          <h2>
            <FontAwesomeIcon icon={faUndo} /> Refund Policy
          </h2>
          <p>
            We offer a 30-day money-back guarantee on all our products. If you
            are not satisfied with your purchase, please contact our support
            team for a refund.
          </p>
        </section>

        <section id="cookie-policy" className="policy-section">
          <h2>
            <FontAwesomeIcon icon={faCookieBite} /> Cookie Policy
          </h2>
          <p>
            Our website uses cookies to enhance your experience. You can manage
            your cookie preferences through our cookie consent banner.
          </p>
        </section>

        <section id="contact-us" className="policy-section">
          <h2>
            <FontAwesomeIcon icon={faEnvelope} /> Contact Us
          </h2>
          <p>
            If you have any questions or concerns about our policies, please
            feel free to reach out to us at support@example.com.
          </p>
        </section>
      </div>
      <LFooter />
    </div>
  );
};

export default Policy;
