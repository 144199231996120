import React from "react";
import "./hero-section.css";
import logo from "../../../assets/Logo-White.png";
import { motion } from "framer-motion";
import { PaperPlaneTilt } from "@phosphor-icons/react";
import AboutUs from "../../About/AboutUs";
import SaleBanner from "../../SaleBanner/SaleBanner";

const HeroSection = ({ onClick }) => {
  return (
    <>
      <section className="hero-section">
        <motion.div
          initial={{ filter: "blur(0px)", opacity: 0 }}
          animate={{ filter: "blur(1rem)", opacity: 1 }}
          transition={{ duration: 1.6 }}
          viewport={{ once: true }}
          className="overlay"
        />

        <div className="hero-content">
          <motion.img
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            viewport={{ once: true }}
            src={logo}
            height={"15%"}
            alt="hero"
            className="hero-image"
          />

          <motion.p
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.6 }}
            viewport={{ once: true }}
            className="hero-subtitle poppins-light"
            style={{ marginTop: "2rem", color: "white" }}
          >
            Simplify, Create, Inspire
          </motion.p>

          <div className="hero-section-action-buttons">
            <motion.button
              type="button"
              className="btn btn-lg rounded-pill btn-success px-5 d-flex align-items-center gap-2 large-button"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              transition={{ duration: 0.2 }}
              onClick={(e) => onClick(e)}
            >
              <span>Join for Free</span>
              <PaperPlaneTilt size={20} />
            </motion.button>
          </div>
        </div>
      </section>

      <SaleBanner onClick={onClick} />

      {/* Video Section */}
      <section className="video-section">
        <div className="video-container">
          <div className="video-content">
            <iframe
              width="100%"
              height="500px"
              src="https://www.youtube.com/embed/u_NENHbsh3g?si=dytbol8GgETa5_1H"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </section>

      <AboutUs />
      {/* Text and Small Buttons Section */}
    </>
  );
};

export default HeroSection;
