import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import AppRoutes from "./AppRoutes"; // Import routes configuration
import { Layout } from "./components/Layout";
import "./custom.css";

export const App = () => {
  const location = useLocation();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-3QJFT7P4EV`;
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-3QJFT7P4EV");
  }, []);

  useEffect(() => {
    if (window.gtag) {
      window.gtag("config", "G-3QJFT7P4EV", {
        page_path: location.pathname,
      });
    }
  }, [location]);

  return (
    <Layout>
      <Routes>
        {AppRoutes.map((route, index) => {
          const { element, ...rest } = route;

          return <Route key={index} {...rest} element={element} />;
        })}
      </Routes>
    </Layout>
  );
};

export default App;
