import React from "react";
import {
  Navbar as BNavbar,
  Nav as BNav,
  Container,
  NavDropdown,
} from "react-bootstrap";
import LogoWhite from "../../../assets/Logo-White.png";
import LogoBlue from "../../../assets/Logo-Blue.png";
import "./navbar.css";
import { List } from "@phosphor-icons/react";
import { Link } from "react-router-dom";

const Navbar = ({ darkNavbar }) => {
  return (
    <BNavbar
      fixed={"top"}
      expand="lg"
      className={darkNavbar ? "navbar-dark" : "navbar"}
    >
      <Container fluid>
        <BNavbar.Brand
          as={Link}
          to="/"
          className="d-flex align-items-center ml-4"
        >
          {darkNavbar && <img src={LogoBlue} alt="logo" height={48} />}
        </BNavbar.Brand>
        <BNavbar.Toggle
          aria-controls="navbarScroll"
          style={{ borderColor: darkNavbar ? "black" : "white" }}
        >
          <List color={darkNavbar ? "black" : "white"} />
        </BNavbar.Toggle>
        <BNavbar.Collapse id="navbarScroll">
          <BNav
            className="ms-auto my-2 my-lg-0 gap-3"
            style={{ padding: "0 3rem" }}
            navbarScroll
          >
            {/* <a
              href="#choose-us-section"
              className={darkNavbar ? "brtt-link-dark" : "brtt-link"}
            >
              Why Choose Us
            </a>
            <a
              href="#key-features-section"
              className={darkNavbar ? "brtt-link-dark" : "brtt-link"}
            >
              Key Features
            </a>
            <a
              href="#testimonials-section"
              className={darkNavbar ? "brtt-link-dark" : "brtt-link"}
            >
              Testimonials
            </a>
            <a
              href="#contact-section"
              className={darkNavbar ? "brtt-link-dark" : "brtt-link"}
            >
              Contact Us
            </a> */}
            <a
              href="https://admin.inspir-edu.com/admin-login"
              target="_blank"
              rel="noopener noreferrer"
              className={darkNavbar ? "brtt-link-dark" : "brtt-link"}
            >
              Start Trial
            </a>
            <a
              href="#faq-section"
              className={darkNavbar ? "brtt-link-dark" : "brtt-link"}
            >
              FAQ
            </a>

            {/* Dropdown for Benefits */}
            <NavDropdown
              title="Benefits"
              id="navbarScrollingDropdown"
              className={`dropdown-custom ${darkNavbar ? "dropdown-dark" : ""}`}
              menuVariant={"light"}
              style={{
                color: darkNavbar ? "black" : "white",
                padding: 0,
              }}
            >
              <NavDropdown.Item
                as={Link}
                to="/benefits"
                className="dropdown-item"
              >
                User Benefits
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/teachers"
                className="dropdown-item"
              >
                Teachers Benefits
              </NavDropdown.Item>
            </NavDropdown>

            <a
              href="https://admin.inspir-edu.com/admin-login"
              target="_blank"
              rel="noopener noreferrer"
              className={darkNavbar ? "brtt-link-dark" : "brtt-link"}
            >
              Sign in
            </a>
          </BNav>
        </BNavbar.Collapse>
      </Container>
    </BNavbar>
  );
};

export default Navbar;
