import React from "react";
import { useNavigate } from "react-router-dom";
import "./Benefits.css"; // Include the CSS file
import benefitsHeader from "../../../assets/user-benefits.jpg"; // Adjust the path to your image
import LFooter from "../footer";
import GetDemo from "../../GetDemo/GetDemo";
import Pricing from "../../Pricing/Pricing";
import { Container } from "react-bootstrap";
import DemoSection from "../demo-section/DemoSection";

const Benefits = () => {
  const ADMIN_BASE_URL = "https://admin.inspir-edu.com/register?step=1";
  const navigate = useNavigate();

  const handleSubscribeClick = () => {
    window.open(ADMIN_BASE_URL, "_blank");
  };

  return (
    <div className="benefits-page-container">
      {/* Top Banner Section */}
      <div
        className="top-banner-section"
        style={{
          backgroundImage: `url(${benefitsHeader})`,
        }}
      >
        <div className="banner-content">
          <h1>Maximize Learning with InspirEDU</h1>
          <p>
            Efficient, intuitive, and tailored solutions for educators and
            businesses alike.
          </p>
          <div className="banner-buttons">
            <button
              className="btn btn-success btn-lg mx-3 shadow-sm"
              onClick={handleSubscribeClick}
            >
              Start Free Trial
            </button>
            <button
              className="btn btn-success btn-lg mx-3 shadow-sm"
              onClick={() => navigate("/policy")}
            >
              Our Policy
            </button>
          </div>
        </div>
      </div>

      <div className="container mb-5" style={{ marginTop: "6rem" }}>
        <div className="text-center my-5">
          <h2
            className="fw-bold"
            style={{ fontSize: "2.5rem", color: "#00274D" }}
          >
            Discover Who Can Benefit from Our Platform!
          </h2>
          <p className="text-muted" style={{ fontSize: "1.25rem" }}>
            Are you a School Administrator, Teacher, Business Owner, or HR
            Professional?
          </p>
          {/* <div className="d-flex justify-content-center">
            <button
              className="btn btn-success btn-lg mx-3 shadow-sm"
              style={{ padding: "0.75rem 2rem" }}
              onClick={handleSubscribeClick}
            >
              Start Free Trial
            </button>
            <button
              className="btn btn-primary btn-lg mx-3"
              style={{
                padding: "0.75rem 2rem",
                backgroundColor: "#00274D",
                borderRadius: "0.5rem",
                color: "#fff",
                border: "1px solid #007bff",
                fontWeight: "400",
                textAlign: "center",
                cursor: "pointer",
                transition: "all 0.3s ease",
              }}
              onClick={() => navigate("/policy")}
            >
              Our Policy
            </button>
          </div> */}
        </div>

        <hr className="mb-5" style={{ border: "1px solid #ddd" }} />

        {/* Title Section */}
        <div className="mb-5">
          <h1 className="display-4 text-dark">Educational Plans</h1>
          <p className="lead text-muted" style={{ fontSize: "1.1rem" }}>
            The educational plans offer tailored solutions for different class
            sizes and needs, enhancing classroom engagement and providing
            flexibility in assessment strategies. They cater to educators from
            small groups to entire schools, streamlining teaching and
            administrative tasks while improving learning outcomes. By choosing
            a plan, you can efficiently manage students and assessments while
            leveraging tools designed to boost productivity and instructional
            effectiveness.
          </p>
        </div>

        {/* Educational Plans Section */}
        <div className="row mb-5">
          {["Starter", "Advanced", "Premium", "Institutional"].map(
            (plan, index) => (
              <div key={index} className="col-md-6 mb-4">
                <div
                  className="card border-0 shadow-md h-100"
                  style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }} // Adding custom box shadow
                >
                  <div className="card-body">
                    <h3 className="card-title text-success fw-bold">
                      {plan} Educator Package ($
                      {index === 0
                        ? "99"
                        : index === 1
                        ? "149"
                        : index === 2
                        ? "249"
                        : "399"}
                      /month)
                    </h3>
                    <ul className="list-unstyled mt-3">
                      <li>
                        {index === 0
                          ? "Perfect for small classes with up to 15 students."
                          : ""}
                      </li>
                      <li>
                        {index === 1
                          ? "Ideal for elementary classrooms, up to 30 students."
                          : ""}
                      </li>
                      <li>
                        {index === 2
                          ? "For high school teachers with up to 150 students."
                          : ""}
                      </li>
                      <li>
                        {index === 3
                          ? "Supports entire schools or departments with up to 300 students."
                          : ""}
                      </li>
                      <li>
                        Includes{" "}
                        {index === 0
                          ? "50"
                          : index === 1
                          ? "100"
                          : index === 2
                          ? "300"
                          : "unlimited"}{" "}
                        tests/training modules.
                      </li>
                      <li>
                        Enhances learning with tailored assessments and
                        curriculum coverage.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )
          )}
        </div>

        {/* Business Plans Section */}
        <div className="mb-5">
          <h1 className="display-4 text-dark">Business Plans</h1>
          <p className="lead text-muted" style={{ fontSize: "1.1rem" }}>
            The business packages offer structured training solutions for teams
            of all sizes, from startups to large corporations, aimed at
            improving employee engagement and aligning with organizational
            goals. They streamline training processes, ensuring consistent
            delivery across departments and locations. With tools to enhance
            learning and advanced analytics to measure effectiveness, these
            packages help businesses scale their training efforts efficiently
            and achieve better outcomes.
          </p>
        </div>

        <div className="row mb-5">
          {["Basic", "Standard", "Enterprise", "Corporate"].map(
            (plan, index) => (
              <div key={index} className="col-md-6 mb-4">
                <div className="card border-0 shadow-md h-100">
                  <div className="card-body">
                    <h3 className={`card-title text-primary fw-bold`}>
                      {plan} Team Leader Package ($
                      {index === 0
                        ? "99"
                        : index === 1
                        ? "149"
                        : index === 2
                        ? "249"
                        : "399"}
                      /month)
                    </h3>
                    <ul className="list-unstyled mt-3">
                      <li>
                        {index === 0
                          ? "Tailored for small teams or startups with up to 15 employees."
                          : ""}
                      </li>
                      <li>
                        {index === 1
                          ? "Designed for medium-sized businesses, up to 30 employees."
                          : ""}
                      </li>
                      <li>
                        {index === 2
                          ? "Supports larger departments or companies with up to 150 employees."
                          : ""}
                      </li>
                      <li>
                        {index === 3
                          ? "Supports large corporations with up to 300 employees."
                          : ""}
                      </li>
                      <li>
                        Includes{" "}
                        {index === 0
                          ? "50"
                          : index === 1
                          ? "100"
                          : index === 2
                          ? "300"
                          : "unlimited"}{" "}
                        training modules.
                      </li>
                      <li>
                        Helps teams stay competitive with essential skills and
                        advanced analytics.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
      <GetDemo />
      <Pricing />
      <Container className="teachers-page py-5">
        <DemoSection onClick={handleSubscribeClick} />
      </Container>
      <LFooter />
    </div>
  );
};

export default Benefits;
