import React, { useState, useEffect } from "react";
import "./SaleBanner.css"; // Include the custom CSS
import { Modal, Button } from "react-bootstrap"; // Import Bootstrap components

const SaleBanner = ({ onClick }) => {
  const [showAnimation, setShowAnimation] = useState(true);
  const [showPopup, setShowPopup] = useState(false); // State for controlling pop-up

  useEffect(() => {
    // Delay the modal appearance by 10 seconds (10000 milliseconds)
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 10000);

    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Scroll listener to trigger pop-up
    const handleScroll = () => {
      if (window.scrollY > 300 && showPopup) {
        setShowPopup(true);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showPopup]);

  return (
    <>
      {/* Sticky Banner */}
      <div className="sticky-banner">
        🚀 Act Now for 50% Off Lifetime Pricing!{" "}
        <button className="trial-link-btn" onClick={onClick}>
          Start Your Free Trial Today!
        </button>
      </div>

      {/* Sale Banner */}
      <div className="sale-banner-container">
        <div className="banner-top">
          <h1 className={`intro-text ${showAnimation ? "fade-in" : ""}`}>
            Special Introductory Lifetime Discount 50% Off. Join Today.
          </h1>
        </div>
        <div className={`sale-banner ${showAnimation ? "show" : ""}`}>
          <h2 className="sale-text">LIFETIME PRICING</h2>
          <p className="action-text">
            If you act now, you get 50% off lifetime pricing.
          </p>
          <button
            className="btn btn-primary pulse custom-btn-banner"
            onClick={onClick}
          >
            Start Your Free Trial Today
          </button>
        </div>

        {/* Pop-Up Modal */}
        <Modal
          show={showPopup}
          onHide={() => setShowPopup(false)}
          centered // This will center the modal on the screen
          dialogClassName="custom-modal" // Add a custom class for additional styling
        >
          <Modal.Header closeButton>
            <Modal.Title className="modal-title-custom">
              🚀 Limited Time Offer!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-body-content">
              <h2>Act Now for 50% Off Lifetime Pricing!</h2>
              <p>Start your free trial today and lock in this special rate.</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={() => setShowPopup(false)}>
              Got It!
            </Button>
            <Button variant="primary" onClick={onClick}>
              Start Free Trial
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default SaleBanner;
