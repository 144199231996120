import React from "react";
import { Accordion } from "react-bootstrap";
import "./faq.css";
import { motion } from "framer-motion";

const FAQ = () => {
  return (
    <motion.section
      id="faq-section"
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      viewport={{ once: true }}
      className="faq-section"
    >
      <div className="faq-left-container">
        <h2 className="faq-title poppins-medium">
          Find Answers To Your Questions
        </h2>

        <svg
          className="faq-wave"
          viewBox="0 0 436 92"
          fill="none"
          xmlns="https://www.w3.org/2000/svg"
        >
          <path
            d="M3 11.6683C27.5507 31.3629 46.9422 39.6998 78.4385 38.3C106.882 37.0359 137.821 23.5277 164.275 13.6843C197.028 1.49724 261.319 -8.02763 280.351 30.7667C295.128 60.8895 272.979 96.9103 238.122 87.1069C224.684 83.3275 217.74 65.3879 223.692 52.9421C230.121 39.4995 249.34 33.0192 262.632 29.9179C298.328 21.5889 322.109 55.0269 354.41 60.8997C384.588 66.3865 406.997 52.8685 429.849 34.5864C431.806 33.0205 430.804 43.4396 430.804 46.0455C430.804 55.5985 432.714 51.3586 432.714 44.1356C432.714 39.4902 435.161 29.6135 428.894 32.2521C424.373 34.1558 414.869 34.5865 409.795 34.5865"
            stroke="#56c54e"
            strokeWidth="5"
            strokeLinecap="round"
          />
        </svg>
      </div>

      <div className="faq-body">
        <Accordion flush defaultActiveKey={"0"}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>How easy is it to set up?</Accordion.Header>
            <Accordion.Body>
              It's extremely easy! With our user-friendly interface, you can
              start creating training programs in just minutes, even if you have
              no technical experience.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              How affordable is your platform?
            </Accordion.Header>
            <Accordion.Body>
              We have affordable pricing plans, not flexible, and value and
              training software.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Can I track my employees' progress?
            </Accordion.Header>
            <Accordion.Body>
              We don't have this, they can track progress, enforce completion
              times, streamline the process.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>Is it mobile-friendly?</Accordion.Header>
            <Accordion.Body>
              Yes, our platform is fully mobile-compatible, enabling your team
              to access training materials anytime, anywhere.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </motion.section>
  );
};

export default FAQ;
