import React from "react";
import "./Terms.css";
import LFooter from "../landing-page/footer";

const Terms = () => {
  return (
    <div className="landing-screen-container">
      <div className="terms-content">
        <h1>Terms and Conditions for inspirEDU</h1>
        <p>Welcome to inspirEDU!</p>

        <p>
          These terms and conditions outline the rules and regulations for the
          use of inspirEDU' Website and Services.
        </p>

        <h2>1. Agreement to Terms</h2>
        <p>
          By accessing this website, we assume you accept these terms and
          conditions in full. Do not continue to use inspirEDU' website and
          services if you do not accept all of the terms and conditions stated
          on this page.
        </p>

        <h2>2. Service Description</h2>
        <p>
          inspirEDU provides a cloud-based platform designed for the creation,
          management, and delivery of educational and training content. Our
          services allow users to create classes, add educational content,
          administer tests, and track user progress.
        </p>

        <h2>3. Account Registration</h2>
        <p>
          Users are required to register for an account to access the full
          features of our service. You agree to provide truthful information and
          to keep your account details confidential. You are responsible for all
          activities that occur under your account.
        </p>

        <h2>4. Fees and Payments</h2>
        <p>
          <strong>Subscription Fees:</strong> Users are charged on a
          subscription basis. Fees vary based on the subscription plan chosen.
        </p>
        <p>
          <strong>Payment Terms:</strong> Payments are due on the subscription
          renewal date. Failure to pay may result in the suspension or
          termination of services.
        </p>
        <p>
          <strong>Returned Payments:</strong> A $35 charge will be applied to
          all returned payments due to insufficient funds or any other reasons.
        </p>

        <h2>5. Cancellation Policy</h2>
        <p>
          Subscriptions can be canceled by providing 30 days' written notice to
          inspirEDU. Cancellations will take effect at the end of the current
          billing cycle.
        </p>

        <h2>6. Intellectual Property Rights</h2>
        <p>
          The content provided on inspirEDU, including text, graphics, logos,
          images, as well as the trademarks, are owned by or licensed to us and
          are protected by copyright and trademark laws.
        </p>

        <h2>7. User Content</h2>
        <p>
          Users may upload content to our platform. By uploading content, you
          grant us a worldwide, non-exclusive license to use, reproduce, and
          display such content in connection with the service provided by
          inspirEDU.
        </p>

        <h2>8. Prohibited Uses</h2>
        <p>You are prohibited from using our website and services:</p>
        <ul>
          <li>In any way that is or may be damaging to this Website;</li>
          <li>In any way that impacts user access to this Website;</li>
          <li>
            To engage in any advertising or marketing without express written
            consent.
          </li>
        </ul>

        <h2>9. Termination</h2>
        <p>
          We may terminate or suspend access to our Service immediately, without
          prior notice or liability, for any reason whatsoever, including,
          without limitation, if you breach the Terms.
        </p>

        <h2>10. Disclaimer of Warranties; Limitation of Liability</h2>
        <p>
          Our service is provided "as is" and "as available" without any
          representations or warranties, express or implied. We do not warrant
          that:
        </p>
        <ul>
          <li>the service will meet your specific requirements;</li>
          <li>
            the service will be uninterrupted, timely, secure, or error-free.
          </li>
        </ul>

        <h2>11. Governing Law & Jurisdiction</h2>
        <p>
          These terms will be governed by and construed in accordance with the
          laws of the State of Arizona, and you submit to the non-exclusive
          jurisdiction of the state and federal courts located in Arizona for
          the resolution of any disputes.
        </p>

        <h2>12. Arbitration</h2>
        <p>
          Any disputes arising out of or in connection with these terms and
          conditions shall be resolved through binding arbitration in accordance
          with the rules of the American Arbitration Association.
        </p>

        <h2>13. Changes to Terms</h2>
        <p>
          inspirEDU reserves the right, at our sole discretion, to modify or
          replace these Terms at any time. If a revision is material, we will
          provide at least 30 days' notice prior to any new terms taking effect.
        </p>
      </div>
      <LFooter />
    </div>
  );
};

export default Terms;
