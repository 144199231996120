import React, { useState } from "react";
import { PaperPlaneTilt } from "@phosphor-icons/react";
import "./contact.css";
import { Toast, ToastContainer } from "react-bootstrap";
import { motion } from "framer-motion";
import Typewriter from "typewriter-effect";

// Define word pairs that make sense together
const wordPairs = [
  { word1: "training", word2: "team" },
  { word1: "teaching", word2: "students" },
  { word1: "helping", word2: "class" },
  { word1: "educating", word2: "team" },
  { word1: "benefiting", word2: "audience" },
  { word1: "leading", word2: "company" },
  { word1: "improving", word2: "team" },
  { word1: "saving", word2: "time" },
  { word1: "creating", word2: "courses" },
];

const Contact = ({ onClick }) => {
  const [showToast, setShowToast] = useState(false);

  // Map word pairs to full sentences for Typewriter effect
  const sentences = wordPairs.map(
    (pair) => `Start ${pair.word1} your ${pair.word2} now!`
  );

  return (
    <motion.section
      id="contact-section"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
      viewport={{ once: true }}
      className="revolutionize-section"
    >
      <div className="revolutionize-content">
        <div className="left-container">
          <div className="typewriter-text">
            <Typewriter
              options={{
                strings: sentences, // Use mapped sentences here
                autoStart: true,
                loop: true,
                delay: 50, // Control typing speed
                deleteSpeed: 30, // Control deleting speed
                pauseFor: 1500, // Control pause duration
              }}
            />
          </div>
        </div>

        <div className="revolutionize-hero-actions">
          <motion.button
            type="button"
            className="btn btn-lg rounded-pill btn-theme2-secondary px-5 d-flex align-items-center gap-2 large-button"
            whileHover={{ scale: 1.05 }} // Add hover animation
            whileTap={{ scale: 0.95 }} // Add tap animation
            transition={{ duration: 0.2 }}
            onClick={onClick}
          >
            <span>Transform Your Training!</span>
            <PaperPlaneTilt size={20} />
          </motion.button>
        </div>
      </div>

      <ToastContainer
        className="p-3"
        position={"bottom-center"}
        style={{ zIndex: 1 }}
      >
        <Toast
          className="d-inline-block m-1"
          bg={"success"}
          show={showToast}
          onClose={() => setShowToast(false)}
          autohide
        >
          <Toast.Body>
            Please sign up to get full access to our resources!
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </motion.section>
  );
};

export default Contact;
